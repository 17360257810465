const ibanPattern = /#([a-zA-Z]{2}[a-zA-Z0-9]{19,32})/;
const ibanLinks = Array.from(document.querySelectorAll('a')).filter(link =>
    ibanPattern.test(link.getAttribute('href')),
);

ibanLinks.forEach(link => {
    link.classList.add('iban-link');

    const linkDescription = document.createElement('span');
    const htmlLanguage = document.documentElement.lang.startsWith('de') ? 'de' : 'en';
    const languageStrings = {
        en: '(copy IBAN)',
        de: '(IBAN kopieren)',
    };

    linkDescription.classList.add('visually-hidden');
    linkDescription.textContent = ` ${languageStrings[htmlLanguage]}`;
    link.appendChild(linkDescription);

    link.addEventListener('click', event => {
        event.preventDefault();

        const iban = ibanPattern.exec(link.getAttribute('href'))[1];
        navigator.clipboard.writeText(iban).then(() => {
            link.classList.add('iban-link--copied');

            setTimeout(() => {
                link.classList.remove('iban-link--copied');
            }, 2000);
        });
    });
});
