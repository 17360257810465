const imageAccordions = document.querySelectorAll('.image-accordion');
imageAccordions.forEach(accordion => {
    const container = accordion.querySelector('.image-accordion__items');
    const elements = accordion.querySelectorAll('.image-accordion__item');
    const pictures = accordion.querySelectorAll('.image-accordion__picture');

    const updateImageVisibility = currentElement => {
        pictures.forEach(picture => {
            const accordionId = picture.getAttribute('aria-labelledby');
            const associatedAccordion = document
                .getElementById(accordionId)
                ?.closest('.image-accordion-element');
            picture.style.visibility =
                associatedAccordion === currentElement ? 'visible' : 'hidden';
        });
    };

    container.addEventListener('click', event => {
        const accordionLabel = event.target.closest('.image-accordion-element__label');
        if (accordionLabel) {
            const currentElement = accordionLabel.parentElement;
            elements.forEach(accordion => {
                if (accordion !== currentElement) {
                    accordion.removeAttribute('open');
                }
            });
            updateImageVisibility(currentElement);
        }
    });

    updateImageVisibility(accordion.querySelector('.image-accordion-element[open]'));
});
