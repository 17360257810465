const timelines = document.querySelectorAll('.timeline');

timelines.forEach(timeline => {
    const milestones = timeline.querySelectorAll('.milestone__content');
    const observerOptions = {
        root: timeline,
        rootMargin: '0px',
        threshold: [0.5],
    };

    const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                entry.target.classList.add('milestone__content--visible');
                entry.target.classList.remove('milestone__content--hidden');
                observer.unobserve(timeline);
            }
        });
    }, observerOptions);

    milestones.forEach(milestone => {
        milestone.classList.add('milestone__content--hidden');
        observer.observe(milestone);
    });
});
