const shareButtonContainer = document.querySelector('.share-button-container');
const shareButton = document.querySelector('.share-button');

if (shareButtonContainer && navigator.share) {
    shareButtonContainer.hidden = false;

    shareButton.addEventListener('click', async () => {
        await navigator.share({
            title: document.title,
            url: window.location.href,
        });
    });
}
