const promostrip = document.querySelector('.promostrip');
const hero = document.querySelector('.hero-video');

if (promostrip) {
    const updatePromostripVisibility = () => {
        const showPromostripAfterHeight = hero
            ? hero.offsetTop + hero.offsetHeight + 100
            : window.innerHeight + 100;

        if (window.scrollY + window.innerHeight < showPromostripAfterHeight) {
            promostrip.classList.add('promostrip--hidden');
        } else {
            promostrip.classList.remove('promostrip--hidden');
        }
    };

    document.addEventListener('scroll', updatePromostripVisibility, { passive: true });

    updatePromostripVisibility();
}
